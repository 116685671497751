import {
    faAnchor,
    faTimes,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";

export interface IErrorAlertProps {
    header: JSX.Element;

    icon?: IconDefinition;
    onDismiss?(): void;
}

class ErrorAlert extends React.PureComponent<IErrorAlertProps> {
    public render() {
        const {children, header, icon, onDismiss} = this.props;

        const classNames = ["alert", "alert-danger", "fade", "show"];
        if (onDismiss) {
            classNames.push("alert-dismissible");
        }

        return (
            <div className={classNames.join(" ")} role="alert">
                <h4 className="alert-heading">
                    <FontAwesomeIcon
                        icon={icon ?? faAnchor}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    {header}
                </h4>
                {children}
                {onDismiss && (
                    <button
                        type="button"
                        id="error-close"
                        className="close"
                        aria-label="close"
                        data-testid="error-close"
                        onClick={onDismiss}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            fixedWidth={true}
                            size="sm"
                        />
                    </button>
                )}
            </div>
        );
    }
}

export default ErrorAlert;
