import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Titled} from "react-titled";

import license from "@/services/license";
import {EDeviceClasses} from "@toolbox/models";

import Card from "@toolbox/design/Card";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import MissingLicense from "@toolbox/render-page/MissingLicense";
import T, {intl2Str} from "@translate/T";

interface IDownloadsProps {
    intl: IntlShape;
}

class Downloads extends React.PureComponent<IDownloadsProps> {
    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(this.props.intl, "Downloads | {parent}", {parent});
    }

    public render() {
        const licenses = license.getSopLicenses();

        if (!licenses.frac && !licenses.cent) {
            return (
                <MissingLicense
                    missingDeviceLicense={[
                        EDeviceClasses.Fracture,
                        EDeviceClasses.Reader,
                    ]}
                />
            );
        }

        return (
            <Titled title={this.getTitle}>
                <ContainerCol col={4}>{this.renderContent()}</ContainerCol>
            </Titled>
        );
    }

    private renderContent() {
        return (
            <React.Fragment>
                <h1>
                    <T>Downloads</T>
                </h1>
                <hr />
                <Card title={<T>SEPView client</T>}>
                    <div className="card-body">
                        <div className="mb-3">
                            <T>
                                SEPView client communicates with LUM devices to
                                perform measurements. Requires administrator
                                permission to install.
                            </T>
                        </div>

                        <a href="SepView.Clients.exe" download={true}>
                            <button type="button" className="btn btn-primary">
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    fixedWidth={true}
                                    className="mr-1"
                                />
                                <T>Download</T>
                            </button>
                        </a>
                    </div>
                </Card>
            </React.Fragment>
        );
    }
}

export default injectIntl(Downloads);
