import "./spinkit.css";
import "./Startup.module.scss";

import {faHeartbeat} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {debounce} from "lodash";
import React from "react";

import hardDriveSpace from "@/services/hard-drive-space";
import http from "@/services/http";
import intlLocale from "@/services/intlLocale";
import session from "@/services/session";
import {IDLE_DELAY} from "@toolbox/models";
import {EStartupStates} from "./models";

interface IStartupProps {
    onReady(): void;
}

interface IStartupState {
    state: EStartupStates;
}

class Startup extends React.PureComponent<IStartupProps, IStartupState> {
    public readonly state: IStartupState = {state: EStartupStates.Starting};

    private readonly startup = debounce(this.checkStartupState, 4 * IDLE_DELAY);
    private readonly started = debounce(this.onServerStarted, 4 * IDLE_DELAY);

    public async componentDidMount() {
        await this.checkStartupState();
    }

    public componentWillUnmount() {
        this.startup.cancel();
        this.started.cancel();
    }

    public render() {
        const {state} = this.state;
        if (state === EStartupStates.Error) {
            return this.renderError();
        }

        // can not be translated
        const message =
            state === EStartupStates.Starting
                ? "SEPView Explorer is starting..."
                : "SEPView Explorer started, loading data...";

        return (
            <div styleName="starting">
                <div className="sk-fold sk-center mb-3">
                    <div className="sk-fold-cube" />
                    <div className="sk-fold-cube" />
                    <div className="sk-fold-cube" />
                    <div className="sk-fold-cube" />
                </div>

                <p>{message}</p>
            </div>
        );
    }

    private renderError() {
        return (
            <div styleName="start-error">
                <FontAwesomeIcon
                    icon={faHeartbeat}
                    fixedWidth={true}
                    size="4x"
                />

                <p>SEPView encountered an error during startup.</p>
            </div>
        );
    }

    @boundMethod
    private async checkStartupState() {
        try {
            const {state} = await http
                .get("/api/startup")
                .json<IStartupState>();

            switch (state) {
                case EStartupStates.Completed:
                    this.setState({state}, this.onServerStarted);
                    return; // skip startup check

                case EStartupStates.Error:
                    this.setState({state});
                    break;
            }
        } catch {
            this.setState({state: EStartupStates.Error});
        }

        this.startup();
    }

    @boundMethod
    private async onServerStarted() {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const language = urlParams.get("language") ?? undefined; // if no localstorage, look at url; mostly cause of recorder
            await Promise.all([
                intlLocale.loadUserPreferredLanguage(language),
                hardDriveSpace.retrieve(),
            ]);

            // if no localstorage, look at url; mostly cause of recorder
            const token = urlParams.get("token");
            if (token) {
                await session.refreshToken(token);
            }

            if (session.token) {
                await session.setSession(undefined);
            }

            this.props.onReady();
            return;
        } catch {
            this.setState({state: EStartupStates.Error});
        }

        this.started();
    }
}

export default Startup;
