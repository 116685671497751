import React from "react";

import {EModuleLicenses} from "@/components/license/models";
import {EServerId} from "@shared/models";
import {ILicensedDevice} from "@toolbox/models";
import {ILocalizedText} from "@translate/models";

import {trademark} from "@/Footer";
import T, {intl2Str} from "@translate/T";
import LicenseFeatures from "./LicenseFeatures";

export function getDisplayModule(licensed: EModuleLicenses): ILocalizedText {
    switch (licensed) {
        case EModuleLicenses.FracView:
            return (intl) => intl2Str(intl, "Frac view");

        case EModuleLicenses.FracAnalysis:
            return (intl) => intl2Str(intl, "Analysis");

        case EModuleLicenses.LumView:
            return (intl) => intl2Str(intl, "LUMView") + trademark();

        case EModuleLicenses.Instability:
            return (intl) => intl2Str(intl, "Stability");

        case EModuleLicenses.Integration:
            return (intl) => intl2Str(intl, "Integration");

        case EModuleLicenses.FrontTracking:
            return (intl) => intl2Str(intl, "Front tracking");

        case EModuleLicenses.Psa:
            return (intl) => intl2Str(intl, "Particle size characterization");

        case EModuleLicenses.Filtration:
            return (intl) => intl2Str(intl, "Filtration");

        case EModuleLicenses.Sedimentation:
            return (intl) => intl2Str(intl, "Sedimentation height");

        case EModuleLicenses.FirstDerivative:
            return (intl) => intl2Str(intl, "First derivative");

        case EModuleLicenses.ExtinctionRatio:
            return (intl) => intl2Str(intl, "MWL extinction ratio");

        case EModuleLicenses.Astm:
            return (intl) => intl2Str(intl, "SEPCalc ASTM D7827-12");

        case EModuleLicenses.PsaMwl:
            return (intl) => intl2Str(intl, "Multiple wavelengths PSA");

        case EModuleLicenses.SpocView:
            return (intl) => intl2Str(intl, "Spoc view");

        case EModuleLicenses.SpocCount:
            return (intl) => intl2Str(intl, "Count & concentration");

        case EModuleLicenses.SpocSize:
            return (intl) => intl2Str(intl, "Number weighted PSD");

        case EModuleLicenses.MultiPeaks:
            return (intl) => intl2Str(intl, "Multi peaks");

        case EModuleLicenses.VolumeConcentration:
            return (intl) => intl2Str(intl, "Volume concentration");

        case EModuleLicenses.Hindrance:
            return (intl) => intl2Str(intl, "Hindrance function");
    }
}

function getLicensedModules(
    serverId: EServerId,
    licensed: EModuleLicenses[],
): EModuleLicenses[] {
    const modules: EModuleLicenses[] = [];

    switch (serverId) {
        case EServerId.Centrifuge:
            modules.push(
                EModuleLicenses.LumView,
                EModuleLicenses.Instability,
                EModuleLicenses.Integration,
                EModuleLicenses.FrontTracking,
                EModuleLicenses.Filtration,
                EModuleLicenses.Sedimentation,
                EModuleLicenses.FirstDerivative,
                EModuleLicenses.ExtinctionRatio,
                EModuleLicenses.Astm,
                EModuleLicenses.Psa,
                EModuleLicenses.PsaMwl,
                EModuleLicenses.VolumeConcentration,
                EModuleLicenses.Hindrance,
            );

            if (licensed.includes(EModuleLicenses.Psa)) {
                modules.push(EModuleLicenses.MultiPeaks);
            }

            break;

        case EServerId.Fracture:
            modules.push(
                EModuleLicenses.FracView,
                EModuleLicenses.FracAnalysis,
            );
            break;

        case EServerId.Spoc:
            modules.push(
                EModuleLicenses.SpocView,
                EModuleLicenses.SpocCount,
                EModuleLicenses.SpocSize,
            );

            if (licensed.includes(EModuleLicenses.SpocSize)) {
                modules.push(EModuleLicenses.MultiPeaks);
            }

            break;
    }

    return modules;
}

function isNonBuyable(item: EModuleLicenses) {
    return [EModuleLicenses.Astm].includes(item);
}

interface IModulesProps {
    values: EModuleLicenses[];
    devices: ILicensedDevice[];
}

class ModuleLicense extends React.PureComponent<IModulesProps> {
    public render() {
        const {values, devices} = this.props;

        return (
            <LicenseFeatures
                id="licensed-modules"
                title={<T>Licensed analysis modules</T>}
                values={values}
                devices={devices}
                missing="missingModuleLicenses"
                getLicensed={getLicensedModules}
                getDisplayName={getDisplayModule}
                isNonBuyable={isNonBuyable}
            />
        );
    }
}

export default ModuleLicense;
