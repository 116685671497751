import {CSSProperties} from "react";
import {StylesConfig} from "react-select/src/styles";
import {OptionProps} from "react-select/src/types";

import {LUM_GREEN} from "@/styles/_variables";
import {ILocalizedText} from "@translate/models";

export const customStyles: StylesConfig = {
    control: (provided: CSSProperties, state: OptionProps) => ({
        ...provided,
        borderColor: state.isFocused ? LUM_GREEN : "#ced4da",
        boxShadow: state.isFocused
            ? "0 0 0 0.2rem rgba(19, 152, 57, 0.5)"
            : "inset 0 2px 0 rgba(0, 0, 0, 0.075)",
        height: "calc(1.5em + 0.75rem + 2px)",
        minHeight: "0px", // reset provided min height of 38px
    }),
    option: (provided: CSSProperties, state: OptionProps) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? "rgba(0, 0, 0, 0.05)"
            : "transparent",
        color: "inherit",
    }),
};

export enum EButtonState {
    None,
    InProgress,
    Done,
    Error,
}

export enum ECheckStates {
    NotChecked,
    Indeterminate,
    Checked,
}

export interface IPendingTaskModel {
    id: string;
    isCompleted: boolean;
    status: string;
}

export interface ISelectValue {
    id: string;
    name: ILocalizedText;
    moresearchhelp?: ILocalizedText;
}
