export const MAX_CYCLES = 255;

export enum EDeviceString {
    Frac = "frac",
    Fuge = "fuge",
    Reader = "reader",
    Sizer = "sizer",
    Spoc = "spoc",
    XRay = "xray",
}

export enum ESampleCopiableValues {
    Name,
    Notes,
    CellTypeStamp,
    All,
}

export interface IErrorChannels {
    name: string;
    notes: string;
    position: number;
}

export interface IErrorValues {
    duplicates: IErrorChannels[];
    unbalances: IErrorChannels[];
    missingCounterpart: IErrorChannels[];
}

export interface IMultipleCircles {
    cx: number;
    cy: number;
    position: number;
}

export interface ISop {
    id: number;
    name: string;
    project: number;
}

export interface ISavedResponse {
    id: number;
}
