import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Titled} from "react-titled";

import license from "@/services/license";
import {EDeviceClasses} from "@toolbox/models";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import MissingLicense from "@toolbox/render-page/MissingLicense";
import T, {intl2Str} from "@translate/T";
import RcaCalculator from "./RcaCalculator";

interface IRcaCalcProps {
    intl: IntlShape;
}

class RcaCalc extends React.PureComponent<IRcaCalcProps> {
    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(this.props.intl, "RCA Calculator | {parent}", {parent});
    }

    public render() {
        const licenses = license.getSopLicenses();
        if (!licenses.frac && !licenses.fuge && !licenses.sizer) {
            return (
                <MissingLicense
                    missingDeviceLicense={[
                        EDeviceClasses.Fracture,
                        EDeviceClasses.Fuge,
                        EDeviceClasses.Sizer,
                    ]}
                />
            );
        }

        return (
            <Titled title={this.getTitle}>
                <ContainerCol col={6}>
                    <h1>
                        <T>RCA calculator</T>
                    </h1>
                    <hr />
                    <RcaCalculator />
                </ContainerCol>
            </Titled>
        );
    }
}

export default injectIntl(RcaCalc);
