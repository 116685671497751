import {debounce} from "lodash";
import React from "react";

import {IDLE_DELAY} from "../models";

interface IToastState {
    animation: boolean;
}

class Toast extends React.PureComponent<{}, IToastState> {
    public readonly state: IToastState = {
        animation: false,
    };

    private readonly animate = debounce(
        () => this.setState({animation: true}),
        IDLE_DELAY,
    );

    public componentDidMount() {
        this.animate();
    }

    public componentWillUnmount() {
        this.animate.cancel();
    }

    public render() {
        const {children} = this.props;
        const {animation} = this.state;
        const visible = animation ? " fade show" : "";

        return (
            <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                className={"toast top-right" + visible}
            >
                {children}
            </div>
        );
    }
}

export default Toast;
