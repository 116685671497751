import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import http from "@/services/http";
import session from "@/services/session";
import {EDocumentTypes, IProjectModel} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import NameChecker from "@toolbox/display-blocks/NameChecker";
import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";

export function renderName(
    name: string,
    isHidden: boolean,
    setName: (e: React.ChangeEvent<HTMLInputElement>) => void,
    toggleHidden: (e: React.ChangeEvent<HTMLInputElement>) => void,
    project: number = 0,
) {
    return (
        <React.Fragment>
            <label htmlFor="project-name-trim">
                <T>Project name</T>
            </label>
            <input
                type="text"
                id="project-name-trim"
                className="form-control"
                autoComplete="on"
                required={true}
                value={name}
                onChange={setName}
            />
            <div className="invalid-feedback">
                <T>Project name is required!</T>
            </div>

            <NameChecker
                htmlNativ="small"
                apiUrl="/api/projects/namecheck"
                name={name}
                id={project}
                type={EDocumentTypes.Project}
            />

            <InlineCheckbox
                className="mt-2"
                idSuffix="hidden-project"
                checked={isHidden}
                toggle={toggleHidden}
            >
                <T>Exclude global access rights</T>
            </InlineCheckbox>
        </React.Fragment>
    );
}

interface ICreateProjectProps {
    onClose(created?: IProjectModel): void;
}

interface ICreateProjectState {
    name: string;
    isHidden: boolean;

    createError: boolean;
    created?: IProjectModel;
}

class CreateProject extends React.PureComponent<
    ICreateProjectProps,
    ICreateProjectState
> {
    public readonly state: ICreateProjectState = {
        createError: false,
        name: "",
        isHidden: false,
    };

    private readonly modal = React.createRef<Modal>();

    @boundMethod
    public setName(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({name: e.target.value});
    }

    @boundMethod
    public toggleHidden(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({isHidden: e.target.checked});
    }

    @boundMethod
    public async createProject() {
        const {name, isHidden} = this.state;
        const json: IProjectModel = {id: 0, name: name.trim(), isHidden};

        try {
            const created = await http
                .put("/api/projects", {json})
                .json<IProjectModel>();

            if (isHidden) {
                await session.refreshToken();
            }

            this.setState({created}, this.modal.current?.triggerClose);
        } catch {
            this.setState({createError: true});
        }
    }

    @boundMethod
    public afterClose() {
        this.props.onClose(this.state.created);
    }

    public render() {
        const {name, isHidden} = this.state;

        return (
            <Modal
                ref={this.modal}
                header={<T>Create new project</T>}
                notCentered={true}
                size="sm"
                afterClose={this.afterClose}
            >
                <ValidatedForm
                    suffixId="create-project"
                    onSubmit={this.createProject}
                >
                    <div className="modal-body">
                        {renderName(
                            name,
                            isHidden,
                            this.setName,
                            this.toggleHidden,
                        )}

                        {this.renderCreateError()}
                    </div>

                    <div className="modal-footer">
                        <button
                            type="submit"
                            id="create-project"
                            className="btn btn-primary mr-1"
                            aria-label="create"
                        >
                            <FontAwesomeIcon
                                icon={faSave}
                                fixedWidth={true}
                                className="mr-1"
                            />
                            <T>Create</T>
                        </button>

                        <CancelButton />
                    </div>
                </ValidatedForm>
            </Modal>
        );
    }

    private renderCreateError() {
        if (!this.state.createError) {
            return null;
        }

        return (
            <small id="create-error" className="form-text text-danger">
                <T>Project could not be created!</T>
            </small>
        );
    }
}

export default CreateProject;
