import styles from "./TasksPanel.module.scss";

import {boundMethod} from "autobind-decorator";
import React, {Component} from "react";
import {
    createHtmlPortalNode,
    HtmlPortalNode,
    InPortal,
    OutPortal,
} from "react-reverse-portal";

import modal from "@/services/modal";
import {EFloatStates} from "./models";

import T from "@translate/T";

export function setFloatVisibility(state: ITasksPanelState, visible: boolean) {
    if (visible) {
        return state.floatState === EFloatStates.Visible
            ? null
            : {floatState: EFloatStates.SlideDown};
    }

    return state.floatState === EFloatStates.Hidden
        ? null
        : {floatState: EFloatStates.SlideUp};
}

interface ITasksPanelState {
    hasModal?: boolean;
    floatState: EFloatStates;
    height: number;
}

class TasksPanel extends React.PureComponent<{}, ITasksPanelState> {
    public readonly state: ITasksPanelState = {
        floatState: EFloatStates.Hidden,
        hasModal: modal.hasModal,
        height: 0,
    };

    private readonly card = React.createRef<HTMLDivElement>();
    private readonly panel = React.createRef<HTMLDivElement>();
    private portalNode = createHtmlPortalNode();
    private handlers = new Map<
        Element,
        (intersecting: IntersectionObserverEntry) => void
    >();
    private observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
            const handler = this.handlers.get(entry.target);
            if (!handler) {
                this.observer.unobserve(entry.target);
                continue;
            }

            handler(entry);
        }
    });

    private unsubscribe?: () => void;

    public componentDidMount() {
        const panel = this.panel.current!;
        this.observer.observe(panel);

        this.handlers.set(panel, (entry) => {
            const showFloat =
                entry.intersectionRatio === 0 &&
                entry.boundingClientRect.bottom < 0;

            this.setState(setFloatVisibility(this.state, showFloat));
        });
        const unModal = modal.subscribe({
            modalChanged: (hasModal) => this.setState({hasModal}),
        });

        const height = this.card.current!.clientHeight;
        this.setState({height});

        this.unsubscribe = () => {
            unModal();
        };
    }

    public componentWillUnmount() {
        const panel = this.panel.current!;
        this.handlers.delete(panel);
        this.observer.unobserve(panel);

        this.unsubscribe?.();
    }

    @boundMethod
    public onAnimationEnd(e: React.AnimationEvent<HTMLElement>) {
        if (e.animationName !== styles.slideInDown) {
            return;
        }

        switch (this.state.floatState) {
            case EFloatStates.SlideDown:
                this.setState({floatState: EFloatStates.Visible});
                break;

            case EFloatStates.SlideUp:
                this.setState({floatState: EFloatStates.Hidden});
                break;
        }
    }

    public render() {
        const {floatState, height} = this.state;

        return (
            <React.Fragment>
                <InPortal node={this.portalNode}>
                    <div className="btn-toolbar" role="toolbar">
                        {this.props.children}
                    </div>
                </InPortal>

                <div
                    className="card mb-3"
                    ref={this.card}
                    style={{minHeight: height}}
                >
                    <div className="card-header has-buttons">
                        <div>
                            <T>Tasks</T>
                        </div>
                        <div className="card-height" />
                    </div>
                    <div className="card-body" ref={this.panel}>
                        {floatState === EFloatStates.Hidden && (
                            <OutPortal node={this.portalNode} />
                        )}
                    </div>
                </div>

                {this.renderFloat(this.portalNode)}
            </React.Fragment>
        );
    }

    private renderFloat(node: HtmlPortalNode<Component>) {
        const {floatState, hasModal} = this.state;
        if (floatState === EFloatStates.Hidden || hasModal) {
            return null;
        }

        let className =
            "navbar navbar-expand-lg navbar-light bg-light fixed-top";
        switch (floatState) {
            case EFloatStates.SlideDown:
                className += " " + styles["slide-down"];
                break;

            case EFloatStates.SlideUp:
                className += " " + styles["slide-up"];
                break;
        }

        return (
            <nav
                className={className}
                styleName="div-navbar"
                onAnimationEnd={this.onAnimationEnd}
            >
                <OutPortal node={node} />
            </nav>
        );
    }
}

export default TasksPanel;
