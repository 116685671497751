import React from "react";

import T from "@translate/T";
import Modal from "./Modal";

interface IRolesInfo {
    globals?: boolean; // set to true to display global roles (admin, global admin)

    afterClose(): void;
}

class RolesInfo extends React.PureComponent<IRolesInfo> {
    public render() {
        return (
            <Modal
                header={<T>Roles description</T>}
                noAutoFocus={true}
                size="xl"
                afterClose={this.props.afterClose}
            >
                <div className="modal-body">
                    <table className="table table-sm table-striped table-borderless mb-0">
                        <thead>
                            <tr>
                                <th>
                                    <T>Role</T>
                                </th>
                                <th>
                                    <T>Granted permissions</T>
                                </th>
                                <th>
                                    <T>Notes</T>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <T>Viewer</T>
                                </th>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>
                                                Can see project in projects
                                                list.
                                            </T>
                                        </li>
                                        <li>
                                            <T>Can view SOPs.</T>
                                        </li>
                                        <li>
                                            <T>
                                                Can view and export
                                                measurements.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Can experiment with analyses and
                                                print it.
                                            </T>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>
                                                Cannot rename or delete project.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Cannot create, edit, rename or
                                                execute SOPs.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Cannot rename or delete
                                                measurements.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Cannot save changes to analyses.
                                            </T>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <T>Recorder</T>
                                </th>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>Can execute SOPs.</T>
                                        </li>
                                        <li>
                                            <T>
                                                Can edit stamps during
                                                measurement in Recorder.
                                            </T>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>
                                                Cannot view saved analyses or
                                                reports.
                                            </T>
                                        </li>
                                        <li>
                                            <T>Cannot manage test stamps.</T>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <T>Editor</T>
                                </th>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>Can rename measurements.</T>
                                        </li>
                                        <li>
                                            <T>
                                                Can make changes to any analysis
                                                in project and delete own
                                                analyses.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Can create and make changes to
                                                any SOP in project and delete
                                                own SOPs.
                                            </T>
                                        </li>
                                        <li>
                                            <T>Can manage test stamps</T>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>Cannot delete measurements.</T>
                                        </li>
                                        <li>
                                            <T>
                                                Cannot delete analyses created
                                                by other users.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Cannot delete SOPs created by
                                                other users.
                                            </T>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <T>Manager</T>
                                </th>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>
                                                Can rename and delete project.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Can rename and delete any SOP,
                                                measurement or analysis in
                                                project.
                                            </T>
                                        </li>
                                        <li>
                                            <T>
                                                Can create projects when
                                                assigned as global permission.
                                            </T>
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul className="mb-0">
                                        <li>
                                            <T>
                                                Cannot edit SOPs, measurements
                                                and analyses if Editor
                                                permission is not granted.
                                            </T>
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                            {this.renderGlobals()}
                        </tbody>
                    </table>
                </div>
            </Modal>
        );
    }

    private renderGlobals() {
        if (!this.props.globals) {
            return null;
        }

        return (
            <React.Fragment>
                <tr>
                    <th scope="row">
                        <T>Administrator</T>
                    </th>
                    <td>
                        <ul className="mb-0">
                            <li>
                                <T>Can perform administrator tasks.</T>
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul className="mb-0">
                            <li>
                                <T>Does not have access to projects.</T>
                            </li>
                            <li>
                                <T>
                                    Need's to be assigned to project in order to
                                    see project.
                                </T>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <th scope="row">
                        <T>Global admin</T>
                    </th>
                    <td>
                        <ul className="mb-0">
                            <li>
                                <T>Can perform administrator tasks.</T>
                            </li>
                            <li>
                                <T>Can perform all tasks for all projects.</T>
                            </li>
                        </ul>
                    </td>
                    <td />
                </tr>
            </React.Fragment>
        );
    }
}

export default RolesInfo;
