import "./Index.module.scss";
import logo from "./lumlogo.jpg";

import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import SessionCheck from "@/SessionCheck";
import {intl2Str} from "@translate/T";
import Help from "./Help";
import Languages from "./Languages";
import NavLinks from "./NavLinks";
import Profile from "./Profile";
import Queue from "./queue/Index";

interface INavBarProps {
    intl: IntlShape;
}

class NavBar extends React.PureComponent<INavBarProps> {
    public render() {
        const {intl} = this.props;

        return (
            <header styleName="app-header">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <Link className="navbar-brand" to="/">
                        <img
                            alt={intl2Str(intl, "LUM logo")}
                            styleName="nav-logo"
                            src={logo}
                        />
                    </Link>
                    <Link className="navbar-brand mb-0 h1" to="/">
                        SEPView
                    </Link>

                    <button
                        type="button"
                        data-target="#navbar"
                        data-toggle="collapse"
                        className="navbar-toggler"
                        aria-expanded="false"
                        aria-controls="navbar"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse" id="navbar">
                        <NavLinks />

                        <ul className="navbar-nav">
                            <SessionCheck>
                                <Queue />
                            </SessionCheck>
                            <Help />
                            <Languages />
                            <SessionCheck>
                                <Profile />
                            </SessionCheck>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default injectIntl(NavBar);
