import {
    faCaretDown,
    faCaretRight,
    faCompressArrowsAlt,
    faExpandArrowsAlt,
    faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {motion} from "framer-motion";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import resize from "@/services/resize";
import {ILocalizedText} from "@translate/models";
import {animation, onClosed, onOpen} from "./models";

import {intl2Str} from "@translate/T";

export interface ICardProps {
    title: JSX.Element | string;

    className?: string;
    headerClassName?: string;
    style?: React.CSSProperties;
    suffixId?: string;

    allowFullscreen?: boolean;
    collapsed?: boolean; // optional value to do not show card body
    noMargin?: boolean; // optional to remove margin mb-3
    xOverflow?: boolean;
    yOverflow?: boolean;

    onTitleClick?(collapsed: boolean): void;
    toolTip?: ILocalizedText;
    renderButtons?(intl: IntlShape): JSX.Element | null;
    renderCustomize?(): JSX.Element | null;
    onCustomizeAnimationComplete?(): void; // will be triggered when customize menu is fully shown or removed
    onFullscreen?(): void;
    triggerOnSizeChange?(): void;
    onToggle?(showCustomize: boolean): void; // give Card optional Function to toggle as well once you press the menu-button
}

interface ICardState {
    showCustomize?: boolean;
}

class Card extends React.PureComponent<ICardProps, ICardState> {
    public readonly state: ICardState = {};

    private readonly card = React.createRef<HTMLDivElement>();
    private readonly header = React.createRef<HTMLDivElement>();
    private readonly customize = React.createRef<HTMLDivElement>();
    private readonly buttonGroup = React.createRef<HTMLDivElement>();
    private disabled: (string | null)[] = [];

    private unsubscribe?: () => void;

    public get subtractHeight() {
        const cardHeight = this.header.current?.offsetHeight ?? 0; // offset cause of border
        const customizeHeight = this.customize.current?.offsetHeight ?? 0; // offset cause of border

        return cardHeight + customizeHeight;
    }

    public get isFullscreen() {
        return (
            this.card.current !== null &&
            this.card.current === document.fullscreenElement
        );
    }

    public get fullHeight() {
        return this.card.current?.offsetHeight ?? 0; // offset cause of border
    }

    public get cardWidthNoBorder() {
        return this.card.current?.clientWidth ?? 0;
    }

    public componentDidMount() {
        const {collapsed, triggerOnSizeChange} = this.props;

        if (collapsed) {
            this.disable(this.props.collapsed);
        }

        if (triggerOnSizeChange) {
            this.unsubscribe = resize.subscribe(
                this.card.current!,
                triggerOnSizeChange,
            );
        }
    }

    public componentDidUpdate(prevProps: Readonly<ICardProps>) {
        const {collapsed} = this.props;

        if (collapsed !== prevProps.collapsed) {
            this.disable(collapsed);
        }
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public toggleFromOutside(showCustomize: boolean) {
        this.setState({showCustomize});
    }

    @boundMethod
    public toggleCustomize(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onToggle, onCustomizeAnimationComplete} = this.props;
        const showCustomize = !this.state.showCustomize;
        this.setState({showCustomize}, () => {
            onToggle?.(showCustomize);
            if (!showCustomize) {
                onCustomizeAnimationComplete?.();
            }
        });
    }

    @boundMethod
    public async onFullscreen(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onFullscreen} = this.props;
        if (this.isFullscreen) {
            await document.exitFullscreen();
        } else {
            await this.card.current?.requestFullscreen();
        }

        this.forceUpdate(onFullscreen);
    }

    @boundMethod
    public onTitleClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {collapsed, onTitleClick} = this.props;
        if (!collapsed) {
            // if we remove body, remove customize as well
            this.toggleFromOutside(false);
        }

        onTitleClick?.(!collapsed);
    }

    public render() {
        const {noMargin, className, suffixId, style} = this.props;
        const margin = noMargin ? "" : " mb-3";
        const extra = className ? " " + className : "";

        return (
            <div
                ref={this.card}
                id={"card" + (suffixId ? "-" + suffixId : "")}
                className={`card can-go-fullscreen${margin}${extra}`}
                data-testid={"card" + (suffixId ? "-" + suffixId : "")}
                style={style}
            >
                {this.renderHeader()}
                {this.renderCustomize()}
                {this.renderBody()}
            </div>
        );
    }

    private renderHeader() {
        const {headerClassName} = this.props;

        let className = "card-header has-buttons bg-primary";
        if (headerClassName) {
            if (headerClassName.includes("bg-")) {
                className = `card-header has-buttons ${headerClassName}`;
            } else {
                className += ` ${headerClassName}`;
            }
        }

        return (
            <div ref={this.header} className={className}>
                <IntlContext.Consumer children={this.renderTitle} />
                <div className="card-height" />
                <IntlContext.Consumer children={this.renderButtons} />
            </div>
        );
    }

    @boundMethod
    private renderTitle(intl: IntlShape) {
        const {title, toolTip, onTitleClick, collapsed} = this.props;

        let icon = null;
        let className;
        let onClick;
        let tooltip = toolTip?.(intl);

        if (onTitleClick && !this.isFullscreen) {
            className = "pointer-cursor";
            onClick = this.onTitleClick;
            tooltip = collapsed
                ? intl2Str(intl, "Click to expand.")
                : intl2Str(intl, "Click to collapse.");
            icon = (
                <FontAwesomeIcon
                    icon={collapsed ? faCaretRight : faCaretDown}
                    fixedWidth={true}
                />
            );
        }

        return (
            <div className={className} onClick={onClick} title={tooltip}>
                {icon}
                {title}
            </div>
        );
    }

    @boundMethod
    private renderButtons(intl: IntlShape) {
        const {renderButtons, renderCustomize, allowFullscreen} = this.props;
        const others = renderButtons?.(intl) ?? null;

        let toggle: JSX.Element | null = null;
        if (renderCustomize?.()) {
            let toggleClass = this.transform("btn btn-primary");
            if (this.state.showCustomize) {
                toggleClass += " active";
            }

            toggle = (
                <button
                    type="button"
                    className={toggleClass}
                    title={intl2Str(intl, "Customize")}
                    aria-label={intl2Str(intl, "Customize")}
                    onClick={this.toggleCustomize}
                >
                    <FontAwesomeIcon icon={faSlidersH} fixedWidth={true} />
                </button>
            );
        }

        let fullscreen: JSX.Element | null = null;
        if (allowFullscreen) {
            fullscreen = (
                <button
                    type="button"
                    className={this.transform("btn btn-primary")}
                    title={intl2Str(intl, "Fullscreen")}
                    aria-label={intl2Str(intl, "Fullscreen")}
                    onClick={this.onFullscreen}
                >
                    <FontAwesomeIcon
                        id="fullscreen-never"
                        icon={faExpandArrowsAlt}
                        fixedWidth={true}
                    />
                    <FontAwesomeIcon
                        id="fullscreen-only"
                        icon={faCompressArrowsAlt}
                        fixedWidth={true}
                    />
                </button>
            );
        }

        if (!fullscreen && !others && !toggle) {
            return null;
        }

        return (
            <div ref={this.buttonGroup} className="btn-group btn-group-sm ml-1">
                {fullscreen}
                {others}
                {toggle}
            </div>
        );
    }

    private renderCustomize() {
        const {renderCustomize, onCustomizeAnimationComplete} = this.props;
        const {showCustomize} = this.state;
        if (!showCustomize || !renderCustomize) {
            return null;
        }

        return this.renderMotion(
            this.renderBorder(renderCustomize()),
            onCustomizeAnimationComplete,
        );
    }

    private renderBody() {
        const {children, collapsed, xOverflow, yOverflow} = this.props;

        if (collapsed) {
            return null;
        }

        let className = "";
        if (xOverflow) {
            className = "div-overflow-x";
        }

        if (yOverflow) {
            className = "div-overflow-y";
        }

        if (!className) {
            return this.renderMotion(children);
        }

        return <div className={className}>{this.renderMotion(children)}</div>;
    }

    private renderBorder(child: React.ReactNode) {
        return (
            <div ref={this.customize} className="border-bottom">
                {child}
            </div>
        );
    }

    private renderMotion(
        child: React.ReactNode,
        onAnimationComplete?: () => void,
    ) {
        return (
            <motion.div
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
                onAnimationComplete={onAnimationComplete}
            >
                {child}
            </motion.div>
        );
    }

    private transform(className: string) {
        const {headerClassName} = this.props;
        if (headerClassName?.includes("bg-")) {
            const background = headerClassName
                .split(" ")
                .find((x) => x.includes("bg-"))!
                .slice(3);
            return className.replace("primary", background);
        }

        return className;
    }

    private disable(collapsed?: boolean) {
        if (collapsed) {
            this.disabled = [];
        }

        const buttons =
            this.buttonGroup.current?.querySelectorAll("button.btn") ?? [];
        for (const [i, a] of buttons.entries()) {
            if (collapsed) {
                this.disabled.push(a.getAttribute("disabled"));
                a.setAttribute("disabled", "");
            } else {
                if (typeof this.disabled[i] !== "string") {
                    a.removeAttribute("disabled");
                }
            }
        }
    }
}

export default Card;
