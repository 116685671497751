import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import hardDriveSpace from "@/services/hard-drive-space";
import {BLOCK_VALUE} from "./models";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import T, {intl2Num, intl2Str} from "@translate/T";

interface IBlockWarningState {
    value: number;
}

class BlockWarning extends React.PureComponent<{}, IBlockWarningState> {
    public readonly state: IBlockWarningState = {
        value: hardDriveSpace.value,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = hardDriveSpace.subscribe({
            hardDriveSpaceChanged: (value) => this.setState({value}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        if (this.state.value > BLOCK_VALUE) {
            return this.props.children;
        }

        return (
            <ContainerCol col={4}>
                <div className="rounded alert alert-warning p-3">
                    <h1>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Sepview blocked</T>
                    </h1>
                    <hr />
                    <div className="text-center">
                        <IntlContext.Consumer children={this.renderMessage} />
                    </div>
                </div>
            </ContainerCol>
        );
    }

    @boundMethod
    private renderMessage(intl: IntlShape) {
        return intl2Str(
            intl,
            "Server free space less than {value} GB, no consistent operation possible anymore, please contact your administrator.",
            {value: intl2Num(intl, this.state.value, 1)},
        );
    }
}

export default BlockWarning;
