import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {debounce, kebabCase} from "lodash";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";
import {IDLE_DELAY} from "../models";

export interface ITextInputProps {
    id: string;
    text: string;

    className?: string; // additional class names for the input element
    style?: React.CSSProperties;
    focus?: boolean; // mount class with focus in input
    autoWidth?: boolean; // do not use this lightly

    autoComplete?: string;
    deleteButton?: boolean;
    disabled?: boolean;
    invalidMessage?: string;
    noEmptyOutput?: boolean;
    placeholder?: ILocalizedText;
    readOnly?: boolean;
    required?: boolean;
    title?: string;
    type?: string;

    onChange(text: string, id: string): void;
}

interface ITextInputState {
    text: string;
    previousId: string;
    previousText: string;
}

class TextInput extends React.PureComponent<ITextInputProps, ITextInputState> {
    public readonly state: ITextInputState = {
        previousId: "",
        previousText: "",
        text: "",
    };

    private readonly input = React.createRef<HTMLInputElement>();
    private readonly reset = debounce(this.resetText, IDLE_DELAY);

    private get effective() {
        return this.state.text.trim();
    }

    public static getDerivedStateFromProps(
        props: ITextInputProps,
        state: ITextInputState,
    ): Partial<ITextInputState> {
        if (
            props.id === state.previousId &&
            props.text === state.previousText
        ) {
            return {};
        }

        return {
            previousId: props.id,
            previousText: props.text,
            text: props.text,
        };
    }

    public componentDidMount() {
        const {invalidMessage, focus} = this.props;
        if (invalidMessage) {
            this.input.current?.setCustomValidity(invalidMessage);
        }

        if (focus) {
            this.putFocus();
        }
    }

    public componentDidUpdate(prev: ITextInputProps) {
        this.reset.cancel();

        const {invalidMessage} = this.props;
        if (invalidMessage === prev.invalidMessage) {
            return;
        }

        this.input.current?.setCustomValidity(invalidMessage ?? "");
    }

    public componentWillUnmount() {
        this.reset.cancel();
    }

    @boundMethod
    public putFocus() {
        this.input.current?.focus();
    }

    @boundMethod
    public setText(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({text: e.target.value});
    }

    @boundMethod
    public clear(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onChange, id} = this.props;
        this.reset();
        onChange("", id);
    }

    @boundMethod
    public commitTextValue(e: React.SyntheticEvent) {
        e.preventDefault();

        const {text: value, onChange, id, noEmptyOutput} = this.props;

        // do not allow empty inputs if props say so
        if (noEmptyOutput && this.effective === "") {
            this.resetText();
            return;
        }

        // value changed, notify
        if (this.effective !== value) {
            this.reset();
            onChange(this.effective, id);
            return;
        }

        // value not changed, reset text
        this.resetText();
    }

    @boundMethod
    public handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case "Escape":
                e.preventDefault();

                // note: this triggers after modal closes
                this.resetText();
                break;

            case "Enter":
                this.commitTextValue(e);
                break;
        }
    }

    public render() {
        const {id, deleteButton} = this.props;

        if (!deleteButton) {
            return <IntlContext.Consumer children={this.renderInput} />;
        }

        return (
            <div className="input-group">
                <IntlContext.Consumer children={this.renderInput} />

                <span className="input-group-append">
                    <button
                        type="button"
                        data-role="clear"
                        data-testid={"text-delete-" + kebabCase(id)}
                        aria-label="clear"
                        className="btn btn-sm btn-secondary"
                        tabIndex={-1}
                        onClick={this.clear}
                    >
                        <FontAwesomeIcon icon={faTimes} fixedWidth={true} />
                    </button>
                </span>
            </div>
        );
    }

    @boundMethod
    private renderInput(intl: IntlShape) {
        const {
            autoComplete,
            autoWidth,
            className,
            disabled,
            id,
            placeholder,
            readOnly,
            required,
            style,
            title,
            type,
        } = this.props;
        const {text} = this.state;

        let _style = style;
        if (autoWidth) {
            _style = {..._style, minWidth: text.length + "ch"};
        }

        return (
            <input
                ref={this.input}
                type={type ?? "text"}
                id={kebabCase(id)}
                data-testid={"text-input-" + kebabCase(id)}
                className={className ?? "form-control"}
                style={_style}
                autoComplete={autoComplete ?? "off"}
                disabled={disabled}
                placeholder={placeholder?.(intl)}
                readOnly={readOnly}
                required={required}
                size={8}
                title={title}
                value={text}
                onChange={this.setText}
                onBlur={this.commitTextValue}
                onKeyDown={this.handleKeyDown}
            />
        );
    }

    @boundMethod
    private resetText() {
        this.setState({text: this.state.previousText});
    }
}

export default TextInput;
