import React from "react";

import {BootstrapColors} from "@/components/template/models";

interface IDropdownButtonProps {
    type: BootstrapColors;
    idSuffix: string;
    menu(): JSX.Element | null;

    classNameButton?: string;
    dataTestId?: string;
    disabled?: boolean;
    isButtonGroup?: boolean;
    standAloneButton?: boolean;
    title?: string;
}

interface IDropdownButtonState {
    left: boolean;
}

class DropdownButton extends React.PureComponent<
    IDropdownButtonProps,
    IDropdownButtonState
> {
    public readonly state: IDropdownButtonState = {
        left: false,
    };

    private readonly ref = React.createRef<HTMLButtonElement>();

    public componentDidMount() {
        const ref = this.ref.current;
        if (!ref) {
            return;
        }

        const rect = ref.getBoundingClientRect();
        const middle = rect.left + rect.width / 2;
        const left = middle > window.innerWidth / 2;
        this.setState({left});
    }

    public render() {
        const {isButtonGroup} = this.props;

        if (isButtonGroup) {
            return (
                <div className="dropdown btn-group">{this.renderButton()}</div>
            );
        }

        return this.renderButton();
    }

    private renderButton() {
        const {
            idSuffix,
            type,
            disabled,
            classNameButton,
            dataTestId,
            title,
            children,
            standAloneButton,
            menu,
        } = this.props;
        const {left} = this.state;
        const id = "dropdown-" + idSuffix;

        let className = `btn btn-${type}`;
        if (!standAloneButton) {
            className += " dropdown-toggle";
        }

        if (classNameButton) {
            className += ` ${classNameButton}`;
        }

        return (
            <React.Fragment>
                <button
                    ref={this.ref}
                    type="button"
                    id={id}
                    data-testid={dataTestId ?? id}
                    className={className}
                    disabled={disabled}
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-toggle="dropdown"
                    title={title}
                >
                    {children}
                </button>
                <div
                    className={
                        "dropdown-menu dropdown-menu-" +
                        (left ? "right" : "left")
                    }
                    aria-labelledby={id}
                >
                    {menu()}
                </div>
            </React.Fragment>
        );
    }
}

export default DropdownButton;
