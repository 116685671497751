import {boundMethod} from "autobind-decorator";
import {AnimatePresence} from "framer-motion";
import {ceil, debounce} from "lodash";
import React from "react";
import {RouteComponentProps} from "react-router";

import http from "@/services/http";
import ParamsParser from "@/services/params";
import {IDLE_DELAY} from "@toolbox/models";
import {ESortFields, ITemplateModel, ITemplateResponse} from "./models";

import Pager from "@toolbox/building-blocks/Pager";
import ScrollableTable from "@toolbox/building-blocks/ScrollableTable";
import SortHeader from "@toolbox/building-blocks/SortHeader";
import SearchSuggestions from "@toolbox/display-blocks/SearchSuggestion";
import {sortObject} from "@toolbox/functions/object-iterator";
import SearchInput from "@toolbox/nativ-inputs/SearchInput";
import T from "@translate/T";
import {getBasicParams, setBasicParams} from "../project/search/Index";

interface ITemplateSearchProps extends RouteComponentProps {
    getAll?: boolean;
    renderWithScrollbar?: boolean;

    onChange(templates: ITemplateModel[]): void;
}

interface ITemplateSearchState {
    emptyResult: boolean;
    total: number;
    suggestions?: string[];
}

class TemplateSearch extends React.PureComponent<
    ITemplateSearchProps,
    ITemplateSearchState
> {
    public readonly state: ITemplateSearchState = {
        emptyResult: false,
        total: 0,
    };

    private readonly trigger = debounce(this.refresh, IDLE_DELAY);

    private get params() {
        const parser = new ParamsParser(this.props.location.search);

        return sortObject({
            ...getBasicParams(parser),
            asc: parser.getBoolean("asc", false),
            sort: parser.getString("sort", ESortFields.Saved) as ESortFields,
        });
    }

    private set params(value) {
        const parser = new ParamsParser();
        parser.setBoolean("asc", value.asc, false);
        parser.setString("sort", value.sort, ESortFields.Saved);
        setBasicParams(parser, value);

        const {history, location} = this.props;
        history.replace({...location, search: parser.toString()});
    }

    public async componentDidMount() {
        await this.trigger();
    }

    public componentWillUnmount() {
        this.trigger.cancel();
    }

    @boundMethod
    public search(query: string) {
        this.params = {...this.params, query};
        this.trigger();
    }

    @boundMethod
    public setPage(page: number) {
        this.params = {...this.params, page};
        this.trigger();
    }

    @boundMethod
    public sort(sort: ESortFields, asc: boolean) {
        this.params = {...this.params, asc, sort};
        this.trigger();
    }

    public render() {
        const {suggestions} = this.state;

        return (
            <React.Fragment>
                <SearchInput query={this.params.query} onSearch={this.search} />
                <SearchSuggestions
                    suggestions={suggestions}
                    onClick={this.search}
                />
                {this.renderResults()}
            </React.Fragment>
        );
    }

    private renderResults() {
        const {emptyResult} = this.state;

        if (emptyResult) {
            return (
                <p className="text-center text-warning mt-2">
                    <T>Your search did not match any templates.</T>
                </p>
            );
        }

        return this.renderTable();
    }

    private renderTable() {
        const {total} = this.state;
        const {page, pageSize} = this.params;

        let className =
            "table table-sm table-striped table-borderless table-center";

        if (this.props.renderWithScrollbar) {
            className += " mb-0";

            return (
                <ScrollableTable
                    className={className}
                    thead={this.renderHeader()}
                    tbody={this.renderBody()}
                />
            );
        }

        return (
            <React.Fragment>
                <table className={className}>
                    {this.renderHeader()}
                    {this.renderBody()}
                </table>

                <Pager
                    page={page}
                    total={total}
                    pageSize={pageSize}
                    bottomMargin={true}
                    onChange={this.setPage}
                />
            </React.Fragment>
        );
    }

    private renderHeader() {
        const {asc, sort} = this.params;

        const sortProps = {
            active: sort,
            ascending: asc,
            onSorted: this.sort,
        };

        return (
            <thead className="thead-dark">
                <tr>
                    <th>
                        <SortHeader<ESortFields>
                            name={ESortFields.Name}
                            {...sortProps}
                        >
                            <T>Name</T>
                        </SortHeader>
                    </th>
                    <th>
                        <T>Type</T>
                    </th>
                    <th className="text-center">
                        <SortHeader<ESortFields>
                            name={ESortFields.Saved}
                            isNumber={true}
                            {...sortProps}
                        >
                            <T>Saved</T>
                        </SortHeader>
                    </th>
                </tr>
            </thead>
        );
    }

    private renderBody() {
        return (
            <tbody id="templates-list" data-testid="templates-list">
                <AnimatePresence exitBeforeEnter={true}>
                    {this.props.children}
                </AnimatePresence>
            </tbody>
        );
    }

    @boundMethod
    private async refresh() {
        const {getAll, onChange} = this.props;
        const {page} = this.params;

        try {
            const response = await this.getPage(page);

            if (getAll) {
                for (
                    let i = 2;
                    i <= ceil(response.total / response.items.length);
                    i++
                ) {
                    try {
                        const {items} = await this.getPage(i);
                        response.items = response.items.concat(items);
                    } catch {
                        // do nothing, just some pages missing.
                    }
                }
            }

            this.params = {...this.params, page: response.page};

            this.setState(
                {
                    emptyResult: !response.items.length,
                    suggestions: response.suggestions,
                    total: response.total,
                },
                () => onChange(response.items),
            );
        } catch {
            this.params = {...this.params, page: 1};
            this.setState({total: 0, emptyResult: true}, () => onChange([]));
        }
    }

    private async getPage(page: number) {
        const {getAll} = this.props;
        const params = this.params;
        const pageSize =
            this.params.pageSize !== "all" ? this.params.pageSize : 200;

        const response = await http
            .get("/api/template", {
                searchParams: {
                    ...params,
                    asc: params.asc.toString(),
                    page,
                    pageSize: getAll ? 200 : pageSize,
                },
            })
            .json<ITemplateResponse>();

        return response;
    }
}

export default TemplateSearch;
