import "./AnalyzeTemplate.module.scss";

import {boundMethod} from "autobind-decorator";
import {AnimatePresence, motion} from "framer-motion";
import {debounce} from "lodash";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {HtmlPortalNode, InPortal} from "react-reverse-portal";
import {Link} from "react-router-dom";

import {ITemplateModel, ITemplateResponse} from "@/components/template/models";
import http from "@/services/http";
import {IPaging, pagingDefault} from "@toolbox/building-blocks/models";
import {animation, onClosed, onOpen} from "@toolbox/design/models";
import {IModuleInfo} from "@toolbox/display-blocks/models";
import {IDLE_DELAY} from "@toolbox/models";
import {EServerId} from "../../models";
import {IModuleGroup} from "../analyze-button/models";
import {IModuleItemGroup} from "./models";

import PagerPageSizeFooter from "@toolbox/building-blocks/PagerPageSizeFooter";
import ModuleIcon, {getModuleInfo} from "@toolbox/display-blocks/ModuleIcon";
import SearchSuggestions from "@toolbox/display-blocks/SearchSuggestion";
import SearchInput from "@toolbox/nativ-inputs/SearchInput";
import T from "@translate/T";

function sortTemp(
    groupModules: IModuleGroup[],
    templates: ITemplateModel[],
): IModuleItemGroup[] {
    const itemGroups: IModuleItemGroup[] = groupModules.map(
        ({id, header, modules}) => {
            return {items: [], id, header, modules};
        },
    );

    for (const template of templates) {
        for (const itemGroup of itemGroups) {
            if (itemGroup.modules.includes(template.engine)) {
                itemGroup.items.push(template);
            }
        }
    }

    for (const itemGroup of itemGroups) {
        itemGroup.items.sort((a, b) => a.engine.localeCompare(b.engine));
    }

    return itemGroups.filter((x) => x.items.length > 0);
}

interface IAnalyzeTemplateProps {
    intl: IntlShape;
    project: number;
    serverId: EServerId;

    targets: string;
    modules: IModuleGroup[];
    columns: number;
    portal: HtmlPortalNode<PagerPageSizeFooter>;

    hrefTarget?: string;

    onClick(): void;
}

interface IAnalyzeTemplateState extends IPaging {
    templates: ITemplateModel[];
    suggestions: string[];

    query: string;
    emptyResult: boolean;
}

class AnalyzeTemplate extends React.PureComponent<
    IAnalyzeTemplateProps,
    IAnalyzeTemplateState
> {
    public readonly state: IAnalyzeTemplateState = {
        ...pagingDefault,
        query: "",
        templates: [],
        suggestions: [],
        emptyResult: false,
    };

    private readonly trigger = debounce(this.refresh, IDLE_DELAY);

    private get columns() {
        return Math.floor(this.props.columns / 2);
    }

    public async componentDidMount() {
        await this.trigger();
    }

    public componentWillUnmount() {
        this.trigger.cancel();
    }

    @boundMethod
    public setQuery(query: string) {
        this.setState({query}, this.refresh);
    }

    @boundMethod
    public setPage(page: number) {
        this.setState({page}, this.refresh);
    }

    @boundMethod
    public setPageSize(pageSize: number) {
        this.setState({pageSize}, this.refresh);
    }

    public render() {
        const {suggestions, query} = this.state;

        return (
            <React.Fragment>
                <h1>
                    <T>Analysis templates</T>
                </h1>
                <hr />

                <SearchInput query={query} onSearch={this.setQuery} />
                <SearchSuggestions
                    suggestions={suggestions}
                    onClick={this.setQuery}
                />

                {this.renderContent()}
                {this.renderFooter()}
            </React.Fragment>
        );
    }

    private renderContent() {
        const {emptyResult} = this.state;

        if (emptyResult) {
            return (
                <p className="text-center text-warning">
                    <T>Your search did not match any templates.</T>
                </p>
            );
        }

        return (
            <div className="div-overflow-y mb-3" styleName="list-template">
                {this.renderResults()}
            </div>
        );
    }

    private renderResults() {
        const {modules} = this.props;
        const {templates} = this.state;

        const items = sortTemp(modules, templates);
        const last = items.length - 1;

        return items.map((template, i) => (
            <div key={template.id} className={last !== i ? "mb-3" : undefined}>
                <h5 className="header border-bottom">{template.header}</h5>
                <ul
                    className="list-unstyled mb-0"
                    style={{columns: this.columns}}
                >
                    <AnimatePresence exitBeforeEnter={true}>
                        {this.renderButtons(template, i)}
                    </AnimatePresence>
                </ul>
            </div>
        ));
    }

    private renderButtons(template: IModuleItemGroup, i: number) {
        const columns = this.columns;
        const last = Math.floor(template.items.length - 1 / columns);

        return template.items.map((item) =>
            this.renderButton(
                item,
                getModuleInfo(item.engine),
                last === Math.floor(i / columns),
                i,
            ),
        );
    }

    private renderButton(
        item: ITemplateModel,
        info: IModuleInfo,
        lastRow: boolean,
        i: number,
    ) {
        const {hrefTarget, intl, onClick} = this.props;

        return (
            <motion.li
                key={item.id}
                className={!lastRow ? "mb-1" : undefined}
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
            >
                <Link
                    id={`link-to-${i}`}
                    onClick={onClick}
                    target={hrefTarget}
                    title={info.type(intl)}
                    to={this.getUrl(item.id)}
                >
                    <ModuleIcon icon={info.icon} />
                    <span className="align-middle ml-1">{item.name}</span>
                </Link>
            </motion.li>
        );
    }

    private renderFooter() {
        const {portal} = this.props;
        const {total, pageSize, page} = this.state;

        return (
            <InPortal node={portal}>
                <PagerPageSizeFooter
                    page={page}
                    total={total}
                    pageSize={pageSize}
                    setPage={this.setPage}
                    setPageSize={this.setPageSize}
                />
            </InPortal>
        );
    }

    @boundMethod
    private async refresh() {
        const {page, query, pageSize} = this.state;

        try {
            const response = await http
                .get(`/api/template`, {
                    searchParams: {
                        asc: true,
                        page: page.toString(),
                        pageSize,
                        query,
                        sort: "name",
                    },
                })
                .json<ITemplateResponse>();

            this.setState({
                emptyResult: !response.items.length,
                page: response.page,
                templates: response.items,
                total: response.total,
            });
        } catch {
            this.setState({...pagingDefault, templates: [], emptyResult: true});
        }
    }

    private getUrl(id: number) {
        const {project, targets, serverId} = this.props;
        return `/project/${project}/${serverId}/analyze?template=${id}&${targets}`;
    }
}

export default injectIntl(AnalyzeTemplate);
